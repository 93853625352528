const Config ={
    BASE_URL:process.env.BASE_URL,
    TOKEN:process.env.TOKEN,
    SEND_OTP:process.env.SEND_OTP,
    VARIFYOTP_BASE_URL:process.env.VARIFYOTP_BASE_URL,
    VARIFYOTP:process.env.VARIFYOTP,
    GET_ADDRESS:process.env.GET_ADDRESS,
    NEW_ADDRESS:process.env.NEW_ADDRESS,
    LOGS_BASE_URL:process.env.LOGS_BASE_URL,
    LOGS_TOKEN:process.env.LOGS_TOKEN,
    LOGS_EVENT:process.env.LOGS_EVENT,
    SETTING_BASE_URL:process.env.SETTING_BASE_URL,
    SETTING_CONFIG:process.env.SETTING_CONFIG,
    CHECKOUT_TOKEN:process.env.CHECKOUT_TOKEN,
    SETTING_TOKEN:process.env.SETTING_TOKEN,
    SETTING_CHECKOUT:process.env.SETTING_CHECKOUT,
    SHIPPING_METHOD:process.env.SHIPPING_METHOD,
    SERVICEABILITY:process.env.SERVICEABILITY,
    PAYMENT_BASE_URL: process.env.PAYMENT_BASE_URL,
    PAYMENT_MODE:process.env.PAYMENT_MODE,
    PAYMENT_TOKEN:process.env.PAYMENT_TOKEN,
    PAYMENT_CREATE: process.env.PAYMENT_CREATE, 
    


}
export default Config