import axios from "axios";
import Config from "../Config/Config";
import {logsPostData} from './Logs'
let BE_TOKEN = '';
let tokenExpiryTime = null; 

const generateToken = async () => {
    try {
      const response = await fetch(`${Config.SETTING_BASE_URL}/${Config.CHECKOUT_TOKEN}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: 'Nimbuspost', password: 'AxKnqaxKkaqtSM5', secretKey: "nimbuspostawesome"}),
      });
     // console.log(response.data);
   
      const data = await response.json();
      ///console.log(data.token)
      //if (data.status && data.code === 200) {
        BE_TOKEN = data.token;
        //tokenExpiryTime = data.data.expiry; // Assuming the API returns an expiry timestamp
        return data;
     // }
      //throw new Error('Failed to generate token');
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };
  const getValidToken = async () => {
     if (!BE_TOKEN) {
       await generateToken(); // Generate a new token if it's missing or expired
    }
     return BE_TOKEN;
   };
   
 const createGatewayApi = async () => {
     const validToken = await getValidToken();
     return axios.create({
       baseURL: Config.SETTING_BASE_URL,
       headers: {
         'Content-Type': 'application/json',
        // Authorization: `Bearer ${Config.SETTING_TOKEN}`,
        Authorization: `Bearer ${validToken}`,
       },
     });
   };
   const geGatewayApi = async () => {
   // const validToken = await getValidToken();
    return axios.create({
      baseURL: Config.SETTING_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
       Authorization: `Bearer ${Config.SETTING_TOKEN}`,
       //Authorization: `Bearer ${Config.validToken}`,
      },
    });
  };
export const settingGetData = async (endpoint,  message) => {

    
      try {
        const apiInstance = await geGatewayApi();
      
        const response = await apiInstance.get(endpoint);
        if (response.data.status === true || response.data.code === 200) {
       
           return response.data;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
};
export const checkoutpostData = async (endpoint, data, message)=>{
    try {
        const apiInstance = await createGatewayApi();
       // console.log(data);
        const response = await apiInstance.post(endpoint, data);
        eventlogs( localStorage.getItem('pii'), "CHECKOUT_ORDER", 'Checkout Order');
       // console.log(response);
        //return response.data; // Return the response data
      } catch (error) {
        eventlogs( localStorage.getItem('pii'), "CHECKOUT_ORDER", error);
        console.error('Error posting data:', error?.response?.data || error.message);
        throw error;
      }
}

export const settingPostData = async (endpoint, data, message) => {


    try {
        const apiInstance = await createGatewayApi();

        //console.log(data);
        const response = await apiInstance.post(endpoint, data);
        eventlogs(localStorage.getItem('pii'), message, message);
        return response.data; // Return the response data
      } catch (error) {
        eventlogs(localStorage.getItem('pii'), error, error);
       // console.error('Error posting data:', error?.response?.data || error.message);
        throw error;
      }
};

export const shipmethod = async(endpoint, data, message)=>{
 
  try {
    const apiInstance = await createGatewayApi();
  
    const response = await apiInstance.post(endpoint, data);

    if (response.data.status === true || response.data.code === 200) {
      eventlogs(localStorage.getItem('pii'), message, "Shipment method select");
        
       return response.data;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }

}
const eventlogs = async(pid, type, message)=>{
  const logs={
    "merchant_id":localStorage.getItem('merchant_id'),
     "store_id":localStorage.getItem('store_id'),
      "req_id":pid,
       "event_type":type,
       "message":message
}
const logged = await logsPostData(`${Config.LOGS_BASE_URL}/${Config.LOGS_EVENT}`,logs);


}