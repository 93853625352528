import axios from "axios";
import Config from "../Config/Config";
let BEARER_TOKEN = '';
let tokenExpiryTime = null; 

const generateToken = async () => {
    try {
      const response = await fetch(`${Config.LOGS_BASE_URL}/${Config.LOGS_TOKEN}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: 'nimbuspost', password: 'uhxsNSgqnbnb86T', secretKey: "nimbuspostawesome"}),
      });
     //console.log(response.data);
   
      const data = await response.json();
     //console.log(data)
      //if (data.status && data.code === 200) {
        BEARER_TOKEN = data.token;
        //tokenExpiryTime = data.data.expiry; // Assuming the API returns an expiry timestamp
        return data.token;
     // }
      throw new Error('Failed to generate token');
    } catch (error) {
      console.log(error)
      //console.error('Error generating token:', error);
      throw error;
    }
  };
  const getValidToken = async () => {
    // if (!BEARER_TOKEN || isTokenExpired()) {
       await generateToken(); // Generate a new token if it's missing or expired
    // }
     return BEARER_TOKEN;
   };
   
 const createGatewayApi = async () => {
     const validToken = await getValidToken();
     return axios.create({
       baseURL: Config.BASE_URL,
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${validToken}`,
       },
     });
   };
   

export const logsPostData = async (endpoint, data) => {


    try {
        const apiInstance = await createGatewayApi();
       // console.log(data);
        const response = await apiInstance.post(endpoint, data);
       
        return response; // Return the response data
      } catch (error) {
        console.error('Error posting data:', error?.response?.data || error.message);
        throw error;
      }
};