import axios from 'axios';
import Config from '../Config/Config';
import {logsPostData} from './Logs'
let BEARER_TOKEN = '';
let tokenExpiryTime = null; // 


const generateToken = async () => {
    try {
      const response = await fetch(`${Config.BASE_URL}/${Config.TOKEN}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: process.env.USERNAME, password: process.env.PASSWORD }),
      });
     // console.log(response.data);
  
      const data = await response.json();
    //  console.log(data.data.token)
      if (data.status && data.code === 200) {
        BEARER_TOKEN = data.data.token;

        //tokenExpiryTime = data.data.expiry; // Assuming the API returns an expiry timestamp
        return data.data;
      }
      throw new Error('Failed to generate token');
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };
  
  // Utility to check if the token is expired
  const isTokenExpired = () => {
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    return tokenExpiryTime && currentTime > tokenExpiryTime;
  };

const getValidToken = async () => {
    if (!BEARER_TOKEN ) {
      await generateToken(); // Generate a new token if it's missing or expired
     }
    return BEARER_TOKEN;
  };
  
const createGatewayApi = async () => {
    const validToken = await getValidToken();
    return axios.create({
      baseURL: Config.BASE_URL,
      maxBodyLength: Infinity,
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${validToken}`,
      },
    });
  };
  

export const postData = async (endpoint, data, type) => {
   
    try {
      const apiInstance = await createGatewayApi();
      const response = await apiInstance.post(endpoint, data);
      
     
      if (response.data.status === true || response.data.code === 200) {
       // console.log(response.data.data.pii_id)
        eventlogs(response.data.data.pii_id, type, response.data.data.message);
      
        return response.data; // Return the response data
    } 
    } catch (error) {
      // console.log(error.response.data);
      // console.error('Error posting data:', error?.response?.data || error.message);
      eventlogs(response.data.data.pii_id, "MOBILE_NUMBER_ENETER", error.response.data);
      throw error.response.data;
    }
  };
  
export const orderPostData = async(key, scerect, payload, message)=>{
  try {
    const timetap=Math.floor(new Date().getTime() / 1000);
    const response = await axios.post(
      'http://plugin.com/wp-json/wc/v3/orders',
      payload,
      {
        'headers': {
          'Content-Type': 'application/json',
             'Authorization':'OAuth oauth_consumer_key="ck_6eb240456a884a2a9572d00996713e864a463d65",oauth_signature_method="HMAC-SHA1",oauth_timestamp="${timetap}",oauth_nonce="DlnD3H4v5AL",oauth_version="1.0",oauth_signature="htY%2FrvtCvyCeWOYvI%2Fs0OYJxDfw%3D"'
        },
     
        // auth: {
        //   username: 'ck_6eb240456a884a2a9572d00996713e864a463d65',     // Replace with your WooCommerce Consumer Key
        //   password: 'cs_5a99e1f413326cc5a1cced18338a9b98affe8a03',  // Replace with your WooCommerce Consumer Secret
        // },
      }
    );

    console.log('Order created successfully:', response.data);
    alert('Order created successfully!');
  } catch (error) {
    console.error('Error creating order:', error);
    alert('Failed to create order.');
  }
}
export const vpostData = async (endpoint, data, type) => {
   
  try {
    const apiInstance = await createGatewayApi();
    const response = await apiInstance.post(endpoint, data);
    
   // console.log(response.data.status);
    if ( response.data.status === 200) {
     // console.log(response.data)
      eventlogs(response.data.pii_id, "MOBILE_NUMBER_OTP VERIFY", response.data.message);
      return response.data; // Return the response data
  } 
  } catch (error) {
    // console.log(error.response.data);
    // console.error('Error posting data:', error?.response?.data || error.message);
    eventlogs(response.data.pii_id, "MOBILE_NUMBER_OTP VERIFY", error.response.data);
    throw error.response.data;
  }
  };
export const getData = async (endpoint,  message) => {

    
    try {
      const apiInstance = await createGatewayApi();
    
      const response = await apiInstance.get(endpoint);
     
      if (response.data.status === true || response.data.code === 200) {
       // console.log(response.data);
        eventlogs( localStorage.getItem('pii'), "PREVIOUS_CUSTOMER_ADDRESS", 'List to customers previous address ');
        return response.data;
      }
      //return response.data;
    } catch (error) {
      eventlogs( localStorage.getItem('pii'), "PREVIOUS_CUSTOMER_ADDRESS", error);
      console.error('Error fetching data:', error);
      throw error;
    }
};

const eventlogs = async(pid, type, message)=>{
  const logs={
    "merchant_id":localStorage.getItem('merchant_id'),
     "store_id":localStorage.getItem('store_id'),
      "req_id":pid,
       "event_type":type,
       "message":message
}
const logged = await logsPostData(`${Config.LOGS_BASE_URL}/${Config.LOGS_EVENT}`,logs);


}